import React, { useState, useContext, useMemo, useReducer, useEffect } from 'react';
import { StoreContext } from 'index';
import { observer } from 'mobx-react';
import Validation from 'utils/helpers/validation';
import { toJS } from 'mobx';
import { DataLoading, EmbedHTML } from 'components/Shared';
import useEmployeeId from 'utils/helpers/user';
import validation from 'utils/helpers/validation';

function MenuOptions({
  currentOptions,
  item,
  optionsLoader,
  addItemToCart,
  categoryId,
  forEditOrder,
  ...props
}) {
  const store = useContext(StoreContext);
  const [getItemTotalPrice, setItemTotalPrice] = useState(0);
  const [optionTypesPrice, setOptionTypesPrice] = useState(0);
  const menuStore = toJS(store.menuStore);
  const { employeeId } = new useEmployeeId();

  const initialState = {
    isInTime: true,
    currentPrice: 0,
    basePrice: '',
    optionTypes: [],
    freeAddons: [],
    paidAddons: [],
    instructions: '',
    addItem: {
      options: [],
      addons: {},
      instructions: '',
    },
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      field: 'currentPrice',
      value: parseFloat(getItemTotalPrice).toFixed(2),
    });

    initRequiredCheck();
  }, [currentOptions]);

  console.log('currentOptions', currentOptions);

  const getOptionTypeIDs = () => {
    dispatch({
      field: 'addItem',
      value: { ...provider.addItem, options: Object.values(provider.optionTypes).flat() },
    });
  };

  useMemo(() => {
    let selectedOptionsSum = 0;

    selectedOptionsSum = Number(item.price);

    setItemTotalPrice(
      parseFloat(
        (parseFloat(optionTypesPrice) + (selectedOptionsSum || parseFloat(item.price))).toFixed(2)
      )
    );
  }, [item, optionTypesPrice]);

  useMemo(() => {
    const options = currentOptions;
    getOptionTypeIDs();
    const selectedOptionsValues = Object.values(provider.optionTypes).flat();

    let price = 0;
    options?.forEach((e, index) => {
      e.attributes.options.forEach(option => {
        if (selectedOptionsValues.includes(option.id)) {
          price += parseFloat(option.attributes.price);
        }
      });
    });

    setOptionTypesPrice(price);
  }, [item, provider?.optionTypes]);

  const initRequiredCheck = () => {
    let OT = []; //option types
    let options = currentOptions;
    // Create optionTypes object so that options can separated into their own groups
    // to account for different limits of options per group

    options?.forEach(option => {
      OT = Object.assign({}, OT, {
        [option.id]: [],
      });
    });

    // The following is to check a radio button if the option is required
    options?.forEach(optionType => {
      // Set required for radio buttons
      if (
        optionType.attributes.required &&
        optionType.attributes.options.length &&
        optionType.attributes.choose_upto === 1
      ) {
        let activeOptions = optionType.attributes.options?.filter(
          option => option?.attributes?.availability === 'active'
        );
        if (activeOptions?.length > 0) OT[optionType.id] = activeOptions[0].id;
      }
      // Set required for limited checkbox menu items
      if (
        optionType.attributes.required &&
        optionType.attributes.options.length &&
        (optionType.attributes.choose_upto >= 2 || optionType.attributes.choose_upto === 0)
      ) {
        let activeOptions = optionType.attributes.options?.filter(
          option => option?.attributes?.availability === 'active'
        );
        if (activeOptions?.length > 0) OT[optionType.id] = [activeOptions[0].id];
      }
    });

    dispatch({
      field: 'optionTypes',
      value: OT,
    });
  };

  const radioCheck = (id, optionTypeId, type, required) => {
    let options = Object.values(provider.optionTypes).flat();

    if (Object.values(options).includes(id) && !required) {
      dispatch({
        field: 'optionTypes',
        value: Object.assign({}, provider.optionTypes, {
          [optionTypeId]: [],
        }),
      });
    } else {
      dispatch({
        field: 'optionTypes',
        value: Object.assign({}, provider.optionTypes, {
          [optionTypeId]: id,
        }),
      });
    }
  };

  const onCheckboxChange = (id, optionType, optionTypesArr, event) => {
    //prevent checkbox to uncheck if its required
    if (optionType.attributes.required && optionTypesArr.length === 1 && !event.target.checked) {
      event.preventDefault();
      return;
    }

    dispatch({
      field: 'optionTypes',
      value: Object.assign({}, provider.optionTypes, {
        [optionType.id]: event.target.checked
          ? optionTypesArr?.concat(id)
          : optionTypesArr?.filter(option => option !== id),
      }),
    });
  };

  const addItem = () => {
    let selectedOptions = [];
    Object.keys(provider.optionTypes).forEach(function (key) {
      let isArray = Array.isArray(provider.optionTypes[key]);
      selectedOptions = [
        ...selectedOptions,
        isArray
          ? provider.optionTypes[key]?.map(option_id => {
              return { option_id, option_group_id: key };
            })
          : { option_id: provider.optionTypes[key], option_group_id: key },
      ];
    });
    addItemToCart(item.id, selectedOptions?.flat(), provider.instructions, categoryId);

    dispatch({ type: 'reset' });
    initRequiredCheck();
  };

  return (
    <div>
      {currentOptions?.map(optionType => (
        <div className="d-col px-2" key={optionType.id}>
          {optionType.attributes.choose_upto === 1 && (
            <div class="py-1 w-full">
              <EmbedHTML
                isRow
                color="text-sm text-black"
                text={optionType?.attributes.display_name_with_html}
                append={`${optionType.attributes.required ? '*' : ''}`}
                appendClass="ml-1"
              />

              <div className="border border-black-100 my-2" />

              {optionType?.attributes?.options?.map(option => (
                <div key={option.id} className="d-col p-1.5">
                  <div className="d-row justify-between px-0 md:px-4 text-right">
                    <label class="d-row align-items-center text-xs break-normal">
                      <input
                        autoComplete="off"
                        type="radio"
                        value={option.id}
                        class="radio option_select_id mr-2"
                        checked={provider.optionTypes[optionType.id] == option.id}
                        disabled={option.attributes?.availability !== 'active'}
                        onChange={e =>
                          radioCheck(
                            option.id,
                            optionType.id,
                            optionType.attributes.name,
                            optionType.attributes.required
                          )
                        }
                      />

                      <EmbedHTML
                        color="text-sm text-black"
                        text={option.attributes.display_name_with_html}
                      />
                    </label>

                    <small className="font-inter-medium text-xs">
                      {option?.attributes?.availability === 'sold_out'
                        ? 'Sold Out'
                        : `+ ${Validation.priceFormatter(option.attributes.price)}`}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          )}

          {optionType.attributes.choose_upto === 0 && (
            <div class="py-1 w-full">
              <EmbedHTML
                isRow
                color="text-sm text-black"
                text={optionType?.attributes.display_name_with_html}
                append={`(Unlimited) ${optionType.attributes.required ? '*' : ''}`}
                appendClass="ml-1"
              />

              <div className="border border-black-100 my-2" />

              {optionType?.attributes?.options?.map(option => (
                <div key={option.id} className="d-col p-1.5">
                  <div className="d-row justify-between px-0 md:px-4 text-right">
                    <label class="d-row align-items-center font-inter-regular text-xs break-normal">
                      <input
                        autoComplete="off"
                        type="checkbox"
                        disabled={option.attributes?.availability !== 'active'}
                        value={option.id}
                        checked={provider.optionTypes[optionType.id]?.includes(option.id)}
                        onChange={e =>
                          onCheckboxChange(
                            option.id,
                            optionType,
                            provider?.optionTypes[optionType.id],
                            e
                          )
                        }
                        class=" mr-2"
                      />

                      <EmbedHTML
                        color="text-sm text-black"
                        text={option.attributes.display_name_with_html}
                      />
                    </label>

                    <small className="font-inter-medium text-xs">
                      {option?.attributes?.availability === 'sold_out'
                        ? 'Sold Out'
                        : `+ ${Validation.priceFormatter(option.attributes.price)}`}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          )}

          {optionType.attributes.choose_upto >= 2 && (
            <div class="py-1 w-full">
              <EmbedHTML
                isRow
                color="text-sm text-black"
                text={optionType?.attributes.display_name_with_html}
                append={`choose up to ${optionType.attributes.choose_upto} ${
                  optionType.attributes.required ? '*' : ''
                }`}
                appendClass="ml-1"
              />

              <div className="border border-black-100 my-2" />

              {optionType?.attributes?.options?.map(option => (
                <div key={option.id} className="d-col p-1.5">
                  <div className="d-row justify-between px-0 md:px-4 text-right">
                    <label class="d-row align-items-center font-inter-regular text-xs break-normal">
                      <input
                        autoComplete="off"
                        type="checkbox"
                        value={option.id}
                        checked={provider?.optionTypes[optionType.id]?.includes(option.id)}
                        disabled={
                          option.attributes?.availability !== 'active' ||
                          (provider?.optionTypes[optionType.id]?.length >=
                            optionType.attributes.choose_upto &&
                            provider?.optionTypes[optionType.id]?.indexOf(option.id) === -1)
                        }
                        onChange={e =>
                          onCheckboxChange(
                            option.id,
                            optionType,
                            provider?.optionTypes[optionType.id],
                            e
                          )
                        }
                        class=" mr-2"
                      />

                      <EmbedHTML
                        color="text-sm text-black"
                        text={option.attributes.display_name_with_html}
                      />
                    </label>

                    <small className="font-inter-medium text-xs">
                      {option?.attributes?.availability === 'sold_out'
                        ? 'Sold Out'
                        : `+ ${Validation.priceFormatter(option.attributes.price)}`}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {optionsLoader && <DataLoading />}

      <div className="d-col mx-2 my-3">
        <small className="text-dark-gray">Special Instructions</small>
        <textarea
          onChange={e => dispatch({ field: 'instructions', value: e.target.value })}
          className="w-100 mt-2 p-2 border border-gray-300 rounded-md text-xs"
          rows="3"
          value={provider.instructions}
          placeholder="Special instructions are fulfilled at restaurant's discretion and not guaranteed"
        />
      </div>

      <div className="d-col mx-2 mt-3">
        <button onClick={() => addItem()} type="button" className="btn-purple text-sm">
          {forEditOrder
            ? `Add ${validation.priceFormatter(getItemTotalPrice)}`
            : `Add to Cart ${validation.priceFormatter(getItemTotalPrice)}`}
        </button>
      </div>
    </div>
  );
}

export default observer(MenuOptions);
