export const capitalizeFirstLetter = string => {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
};

export const changeSpacesToHyphens = string => {
  return string && string.replace(/\s/g, '-');
};

export const removeUnderscore = string => {
  return string?.replace(/_/g, ' ');
};
