import React, { useEffect, useContext, useState, useMemo } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import useEmployeeId from 'utils/helpers/user';
import { EmbedHTML } from 'components/Shared';
import Validation from 'utils/helpers/validation';
import { SpecialInstruction } from 'assets/img';
import validation from 'utils/helpers/validation';
import classNames from 'classnames';
import { errorHandler } from 'utils/middlewares/errorHandler';
import Menu from 'components/Shared/Menu';

function EditCateringOrder({ visible, closeModal, orderId, getOrders, data }) {
  const store = useContext(StoreContext);
  const [order, setOrder] = useState(null);
  const [menu, setMenu] = useState(null);
  const [addItemsActive, setAddItemsActive] = useState(false);
  const { employeeId } = new useEmployeeId();

  let subtotal = 0,
    grandTotalAmount = 0;

  useEffect(() => {
    if (orderId) {
      getCateringOrderDetails();
    }

    return () => {
      setOrder(null);
    };
  }, [orderId, data]);

  const getCateringOrderDetails = () => {
    store.userStore.setLoader(true);
    store.orderStore
      .getCateringOrderDetails(orderId, {
        employee_id: employeeId,
      })
      .then(data => {
        store.userStore.setLoader(false);
        const order = data?.data;
        order && setOrder(data?.data);
      });
  };

  useEffect(() => {
    if (order?.attributes) {
      let updatedSubtotal = 0;

      order?.attributes?.order_items?.forEach(
        ({ attributes: { total_price } }) =>
          (updatedSubtotal = parseFloat(updatedSubtotal) + parseFloat(total_price))
      );

      setOrder({
        ...order,
        attributes: {
          ...order.attributes,
          subtotal: parseFloat(updatedSubtotal),
          grand_total:
            parseFloat(updatedSubtotal) +
            parseFloat(order?.attributes?.tip) +
            parseFloat(order?.attributes?.hst) +
            parseFloat(order?.attributes?.delivery_fee) +
            parseFloat(order?.attributes?.service_fee),
        },
      });
    }
  }, [order?.isUpdateQuantity]);

  subtotal = useMemo(() => {
    let updatedSubtotal = order?.attributes?.subtotal;

    order?.attributes?.order_items?.forEach(orderItem => {
      if (orderItem?.destroy && !orderItem?.attributes?.unpaid) {
        updatedSubtotal =
          parseFloat(updatedSubtotal) - parseFloat(orderItem?.attributes?.total_price);
      }
    });

    return parseFloat(updatedSubtotal);
  }, [
    order?.attributes?.order_items?.filter(orderItem => !orderItem?.destroy)?.length,
    order?.attributes?.subtotal,
  ]);

  grandTotalAmount = useMemo(() => {
    let updatedGrandTotal = order?.attributes?.grand_total;

    order?.attributes?.order_items?.forEach(orderItem => {
      if (orderItem?.destroy && !orderItem?.attributes?.unpaid) {
        updatedGrandTotal =
          parseFloat(updatedGrandTotal) - parseFloat(orderItem?.attributes?.total_price);
      }
    });

    return parseFloat(updatedGrandTotal);
  }, [
    order?.attributes?.order_items?.filter(orderItem => !orderItem?.destroy)?.length,
    order?.attributes?.grand_total,
  ]);

  const removeItem = id => {
    setOrder({
      ...order,
      attributes: {
        ...order?.attributes,
        order_items: order?.attributes?.order_items?.map(orderItem => {
          if (orderItem.id === id)
            return {
              ...orderItem,
              destroy: true,
            };

          return orderItem;
        }),
      },
    });
  };

  const updateQuantity = (id, operator) => {
    setOrder({
      ...order,
      isUpdateQuantity: !order?.isUpdateQuantity,
      attributes: {
        ...order?.attributes,
        order_items: order?.attributes?.order_items?.map(orderItem => {
          if (orderItem.id === id) {
            const updatedQuantity =
              operator == '+'
                ? orderItem?.attributes?.quantity + 1
                : orderItem?.attributes?.quantity > 1
                ? orderItem?.attributes?.quantity - 1
                : orderItem?.attributes?.quantity;

            let optionsPrice = 0;

            orderItem?.attributes?.order_item_options?.forEach(
              option => (optionsPrice += parseFloat(option.attributes?.price))
            );

            return {
              ...orderItem,

              attributes: {
                ...orderItem.attributes,
                quantity: updatedQuantity,
                total_price:
                  updatedQuantity *
                  (parseFloat(orderItem?.attributes?.price) + parseFloat(optionsPrice)),
              },
            };
          }
          return orderItem;
        }),
      },
    });
  };

  const updateOrder = () => {
    let payload = {
      order_items_attributes: order?.attributes?.order_items?.map(orderItem => {
        if (orderItem?.destroy)
          return {
            id: orderItem.id,
            _destroy: orderItem?.destroy,
          };
        else
          return {
            id: orderItem.id,
            quantity: orderItem.attributes?.quantity,
          };
      }),
    };

    store.userStore.setLoader(true);

    store?.orderStore
      .updateCateringOrder(order?.id, payload, {
        employee_id: employeeId,
      })
      .then(data => {
        store.userStore.setLoader(false);

        if (data?.order?.attributes) {
          errorHandler({
            title: 'Order updated successfully.',
          });

          getOrders();

          closeModal();
        }
      });
  };

  const onClickAddItems = () => {
    store.userStore.setLoader(true);
    setAddItemsActive(true);
    store?.menuStore.getMenuDetails(employeeId, order?.attributes?.menu_id).then(response => {
      store.userStore.setLoader(false);

      const data = response?.data?.data?.attributes;

      if (data) {
        setMenu(data);
      }
    });
  };

  const updateOrderToAddItem = (itemId, options, instructions, categoryId) => {
    let payload = {
      order_items_attributes: [
        {
          menu_item_id: itemId,
          quantity: 1,
          menu_category_id: categoryId,
          order_item_options_attributes: options,
          instructions: instructions,
        },
      ],
    };

    store.userStore.setLoader(true);

    store?.orderStore
      .updateCateringOrder(order?.id, payload, {
        employee_id: employeeId,
      })
      .then(data => {
        store.userStore.setLoader(false);

        if (data?.order?.attributes) {
          getCateringOrderDetails();
          setAddItemsActive(false);
        }
      });
  };

  return (
    <div
      className={classNames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ maxHeight: '95%', width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex flex-col">
          <h5 className="font-inter-semibold px-6 pt-6">Edit Order</h5>

          <button
            onClick={() => {
              closeModal('close');
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          {addItemsActive ? (
            <div className="flex flex-col my-5 px-6">
              {menu && (
                <>
                  <button className="mt-3 mb-5" onClick={() => setAddItemsActive(false)}>
                    <div className="flex items-center border-b-2">
                      <FontAwesomeIcon
                        icon={faAngleLeft}
                        color={'#000'}
                        size="icon"
                        className=" mr-3"
                      />

                      <span className="">Go Back</span>
                    </div>
                  </button>

                  <Menu forEditOrder restaurantDetail={menu} addItemToCart={updateOrderToAddItem} />
                </>
              )}
            </div>
          ) : (
            <div className="my-5 px-6">
              <table className="w-full table-fixed">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="t-head w-1/5 text-center">
                      <small className="t-row">Quantity</small>
                    </th>

                    <th scope="col" className="t-head w-1/5">
                      <small className="t-row">Category</small>
                    </th>

                    <th scope="col" className="t-head w-1/5">
                      <small className="t-row">Item</small>
                    </th>

                    <th scope="col" className="t-head text-center w-1/5">
                      <small className="t-row">Food Total</small>
                    </th>

                    <th scope="col" className="t-head text-center w-1/5"></th>
                  </tr>
                </thead>

                <tbody>
                  {order?.attributes?.order_items
                    ?.filter(orderItem => !orderItem?.destroy)
                    ?.map((orderItem, index) => (
                      <>
                        {orderItem?.attributes?.unpaid && (
                          <div className="flex flex-col">
                            <span className="text-sm text-red-500 font-inter-semibold">Unpaid</span>

                            {orderItem?.attributes?.unpaid_quantity > 0 && (
                              <span className="text-sm text-red-500 font-inter-semibold">
                                Unpaid quantity: {orderItem?.attributes?.unpaid_quantity}
                              </span>
                            )}
                          </div>
                        )}

                        <tr
                          key={index}
                          className={classNames({
                            'hover:bg-blue-50 cursor-pointer animate-fade-in-down': true,
                            'border-2 border-red-500': orderItem?.attributes?.unpaid,
                          })}>
                          <td colSpan="6" className={`py-3 text-dark-gray animate-fade-in-down`}>
                            <table className="w-full table-fixed">
                              <tbody>
                                <tr>
                                  <td>
                                    <table className="w-full table-fixed">
                                      <tbody>
                                        <tr className="flex">
                                          <td className="text-center align-top w-1/5">
                                            <div className="flex items-center rounded-sm">
                                              <div
                                                className={'py-1.5 cursor-pointer px-2'}
                                                onClick={() => updateQuantity(orderItem?.id, '-')}>
                                                <div
                                                  className={classNames({
                                                    'bg-black w-2 h-0.5': true,
                                                    'bg-gray-300':
                                                      orderItem?.attributes?.quantity == 1,
                                                  })}
                                                />
                                              </div>

                                              <span className="text-black-light font-inter-medium text-xs w-7 text-center">
                                                {orderItem?.attributes?.quantity}
                                              </span>

                                              <div
                                                className="py-1.5 cursor-pointer px-2 mb-1"
                                                onClick={() => updateQuantity(orderItem?.id, '+')}>
                                                <span
                                                  className="text-md text-black font-inter-medium"
                                                  style={{ lineHeight: 0.8 }}>
                                                  +
                                                </span>
                                              </div>
                                            </div>
                                          </td>

                                          <td className="mt-1.5 text-left align-top w-1/5">
                                            <EmbedHTML
                                              text={
                                                orderItem?.attributes
                                                  ?.category_display_name_with_html
                                              }
                                              color="text-black text-xs lg:text-sm"
                                            />
                                          </td>

                                          <td className="mt-1.5 align-top w-1/5">
                                            <div className="pb-4">
                                              <p className="overflow-ellipsis overflow-hidden text-xs d-row lg:text-sm text-black font-inter-regular">
                                                {orderItem?.attributes?.special_instructions && (
                                                  <img
                                                    src={SpecialInstruction}
                                                    className="object-contain h-6 w-6 pr-2"
                                                  />
                                                )}

                                                <EmbedHTML
                                                  text={
                                                    orderItem?.attributes?.menu_item
                                                      ?.display_name_with_html
                                                  }
                                                  color="text-black capitalize"
                                                />
                                              </p>

                                              {orderItem?.attributes?.special_instructions && (
                                                <p className="overflow-ellipsis overflow-hidden text-xs lg:text-xs text-pink-500 font-inter-regular">
                                                  {orderItem?.attributes?.special_instructions}
                                                </p>
                                              )}

                                              {orderItem?.attributes?.order_item_options?.map(
                                                ({ attributes }, index) => (
                                                  <div className="d-col" key={`${index}optionName`}>
                                                    <EmbedHTML
                                                      text={
                                                        attributes.option_group_display_name_with_html
                                                      }
                                                      append={':'}
                                                      color="text-dark-blue text-xs break-normal pt-2 mr-1"
                                                    />

                                                    <EmbedHTML
                                                      text={
                                                        attributes.option_display_name_with_html
                                                      }
                                                      color="text-gray-500 text-xs break-normal mr-1"
                                                    />
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </td>

                                          <td className="mt-1.5 text-center align-top w-1/5">
                                            <div className="d-col">
                                              <small className="font-inter-regular text-black text-xs lg:text-sm flex flex-col">
                                                {Validation.priceFormatter(
                                                  orderItem?.attributes?.total_price
                                                )}
                                              </small>
                                            </div>
                                          </td>

                                          <td className="mt-1.5 text-center align-top w-1/5">
                                            {order?.attributes?.order_items?.length > 1 && (
                                              <button
                                                onClick={() => removeItem(orderItem?.id)}
                                                type="button">
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                  color={'#ADB4C3'}
                                                  size="1x"
                                                />
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>

              {order && (
                <>
                  <div className="flex flex-col justify-center mt-5 text-sm">
                    <div className="flex my-1">
                      <span className="font-semibold">Subtotal: </span>
                      <span>{validation.priceFormatter(subtotal)}</span>
                    </div>

                    <div className="flex my-1">
                      <span className="font-semibold">Tip: </span>
                      <span>{validation.priceFormatter(order?.attributes?.tip)}</span>
                    </div>

                    <div className="flex my-1">
                      <span className="font-semibold">Tax: </span>
                      <span>{validation.priceFormatter(order?.attributes?.hst)}</span>
                    </div>

                    <div className="flex my-1">
                      <span className="font-semibold">Service fee: </span>
                      <span>{validation.priceFormatter(order?.attributes?.service_fee)}</span>
                    </div>

                    <div className="flex my-1">
                      <span className="font-semibold">Delivery fee: </span>
                      <span>{validation.priceFormatter(order?.attributes?.delivery_fee)}</span>
                    </div>

                    <div className="flex my-1">
                      <span className="font-semibold">Grand Total: </span>
                      <span>{validation.priceFormatter(grandTotalAmount)}</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <button
                      type="button"
                      onClick={onClickAddItems}
                      className="btn-outline self-center md:w-auto mr-2 px-5 mt-2 md:mt-0">
                      <small>Add Item</small>
                    </button>

                    <button
                      type="button"
                      onClick={updateOrder}
                      className="btn-purple self-center md:w-auto px-5 mt-2 md:mt-0">
                      <small>Edit Order</small>
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditCateringOrder;
