import React, { useState, useContext } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { StoreContext } from 'index';
import { observer } from 'mobx-react';
import Validation from 'utils/helpers/validation';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Icon from 'utils/helpers/icon';
import { toJS } from 'mobx';
import { MenuElementStatus, MenuOptions, EmbedHTML } from 'components/Shared';
import useEmployeeId from 'utils/helpers/user';
import ProgressiveImage from 'react-progressive-image';
import { Placeholder } from 'assets/img';

function Menu({ restaurantDetail, addItemToCart, forEditOrder }) {
  const store = useContext(StoreContext);
  const [selected, setSelected] = useState(null);
  const [optionsLoader, setOptionsLoader] = useState(false);
  const menuStore = toJS(store.menuStore);
  const { employeeId } = new useEmployeeId();
  let { optionCategories } = menuStore;

  const getOptionCategories = id => {
    if (id[0] !== selected) {
      setSelected(id[0]);
      setOptionsLoader(true);
      store.menuStore.clearOptionCategories();
      store.menuStore
        .getOptionCategories(id[0], employeeId)
        .then(response => setOptionsLoader(false));
    }
  };

  return (
    <div className="w-full">
      <Screen.Desktop>
        <strong className="font-inter-semibold text-lg">MENU</strong>
      </Screen.Desktop>

      {restaurantDetail?.menu_categories?.length > 0 ? (
        <Accordion
          onChange={id => {
            getOptionCategories(id);
          }}
          allowZeroExpanded>
          {restaurantDetail?.menu_categories.map((category, index) => (
            <div className={`overflow-hidden mt-2 rounded-t-xl`}>
              <div className="bg-light-purple py-4 d-row justify-between px-2">
                <div className="d-col w-3/4">
                  <EmbedHTML
                    text={category?.attributes?.display_name_with_html}
                    color="text-sm text-white"
                  />

                  <EmbedHTML
                    text={category?.attributes?.description_with_html}
                    color="opacity-90 text-xxs text-white"
                  />
                </div>

                <MenuElementStatus status={category?.attributes?.availability} light mediumSized />
              </div>
              {category?.attributes?.menu_items.map(({ attributes }, index) => (
                <AccordionItem key={attributes.id} uuid={attributes.id}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <div
                            className={`p-5 mb-1 border rounded-lg shadow-md hover:shadow-lg  ${
                              (index === 0 ? ' rounded-tl-none rounded-tr-none' : 'mt-2',
                              (attributes?.availability === 'sold_out' ||
                                attributes?.availability === 'in_active') &&
                                'bg-gray-100')
                            }`}>
                            <div className="d-row justify-between">
                              <EmbedHTML
                                text={attributes.display_name_with_html}
                                color="text-sm mr-2 text-black"
                              />
                              <MenuElementStatus status={attributes?.availability} />
                            </div>

                            <div className="d-row items-start justify-between">
                              <EmbedHTML
                                text={attributes.description_with_html}
                                color="text-dark-gray text-xs pr-12"
                              />

                              <div className="d-row items-end w-1/4 justify-end">
                                {attributes.image_url && (
                                  <ProgressiveImage
                                    src={attributes.image_url}
                                    placeholder={Placeholder}>
                                    {src => (
                                      <img
                                        src={src}
                                        className="h-24 w-24 bg-white object-contain shadow-sm rounded-md border"
                                        alt="an image"
                                      />
                                    )}
                                  </ProgressiveImage>
                                )}
                              </div>
                            </div>

                            <div className="d-col justify-start mt-3">
                              <div className="grid grid-cols-12 gap-2 w-3/4">
                                {attributes?.badges?.map(badge => (
                                  <img
                                    title={badge}
                                    src={Icon.menuItemBadge(badge)}
                                    alt="an image"
                                    className="object-contain h-6 w-6"
                                  />
                                ))}
                              </div>

                              <div className="d-row justify-between">
                                <small className="font-inter-medium text-sm mt-4">
                                  {Validation.priceFormatter(attributes.price)}
                                </small>

                                <button className="btn-purple bg-dark-purple h-10 flex justify-center items-center w-10">
                                  <FontAwesomeIcon
                                    icon={
                                      attributes?.availability !== 'sold_out' &&
                                      attributes?.availability !== 'in_active'
                                        ? expanded
                                          ? faChevronUp
                                          : faChevronDown
                                        : faChevronDown
                                    }
                                    color={'#fff'}
                                    size="md"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  {attributes?.availability !== 'sold_out' &&
                    attributes?.availability !== 'in_active' && (
                      <AccordionItemPanel>
                        <div className="border bg-white py-3">
                          <MenuOptions
                            forEditOrder={forEditOrder}
                            currentOptions={
                              optionCategories?.data?.data?.attributes?.option_categories
                            }
                            optionsLoader={optionsLoader}
                            item={attributes}
                            categoryId={category?.attributes?.id}
                            addItemToCart={addItemToCart}
                          />
                        </div>
                      </AccordionItemPanel>
                    )}
                </AccordionItem>
              ))}
            </div>
          ))}
        </Accordion>
      ) : (
        <div className="py-4 mt-5">
          <span className="font-inter-medium text-sm">
            No menu items available for the selected restaurant.
          </span>
        </div>
      )}
    </div>
  );
}

export default observer(Menu);
