export default {
  login: () => `/v1/sessions`,
  orderSummaries: () => `/v1/restaurant_admin/shipments.json`,
  orders: () => `/v1/restaurant_admin/shipments`,
  employees: () => `/v1/restaurant_admin/employees`,
  forgotPassword: () => `/v1/passwords/new`,
  menus: restaurant_id => `/v1/restaurant_admin/menus?employee_id=${restaurant_id}`,
  optionCategories: (menuCategoryId, restaurant_id) =>
    `/v1/restaurant_admin/menu_items/${menuCategoryId}?employee_id=${restaurant_id}`,
  resetPassword: () => `/v1/passwords`,
  bankingInformation: (userId, restaurantId) =>
    `/v1/restaurant_admin/banking_information/${userId}?employee_id=${restaurantId}`,
  accountingEmails: (userId, restaurantId) =>
    `/v1/restaurant_admin/accounting_emails/${userId}?employee_id=${restaurantId}`,
  salesReport: () => `/v1/restaurant_admin/reports`,
  restaurantProfile: (userId, restaurantId) =>
    `/v1/restaurant_admin/restaurant_profiles/${userId}?employee_id=${restaurantId}`,
  userProfile: (userId, restaurantId) =>
    `/v1/restaurant_admin/profiles/${userId}?employee_id=${restaurantId}`, //to get the user profile info and to update notification settings
  profile: (userId, restaurantId) => `/v1/profiles/${userId}?employee_id=${restaurantId}`, //to update the user profile
  orderXLS: () => `/v1/restaurant_admin/orders.xlsx`,
  schedules: () => `/v1/restaurant_admin/schedules`,
  eta: () => `/v1/restaurant_admin/estimated_arrivals`,
  device: restaurantId => `/v1/restaurant_admin/devices?employee_id=${restaurantId}`,
  fetchProfile: (email, restaurantId) => `/v1/profiles/${email}?employee_id=${restaurantId}`,
  notifications: () => `/v1/restaurant_admin/notifications`,
  cancellation: () => `/v1/restaurant_admin/orders`,
  report: () => `/v1/restaurant_admin/reports`,
  fortnightly: () => `/v1/restaurant_admin/restaurant_profiles`,
  menu: (menuId, restaurantId) =>
    `/v1/restaurant_admin/menus/${menuId}?employee_id=${restaurantId}`,
  cateringOrderDetails: id => `/v1/restaurant_admin/orders/${id}`,
  updateCateringOrder: id => `/v2/restaurant_admin/orders/${id}`,
  operatingHoursAvailable: () => `v1/restaurant_admin/operating_hours`,
  menuWithId: (id, restaurantId) => `v1/restaurant_admin/menus/${id}?employee_id=${restaurantId}`,
};
