import React, { useContext, useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { NotFound } from 'components/Shared';
import Validation from 'utils/helpers/validation';
import { observer } from 'mobx-react';
import { SpecialInstruction } from 'assets/img';
import { BagDetail } from 'components/Alerts';
import { useExportMethods } from 'hooks/useExportMethods';
import { useFreshChatMethods } from 'hooks/useFreshChatMethods';
import orderHelper from 'utils/helpers/order';
import array from 'utils/helpers/array';
import { useQueryMethods } from 'hooks/useQueryMethods';
import { StoreContext } from 'index';
import { toJS } from 'mobx';

function CateringOrderMobile({
  data,
  expandCollapse,
  withSentStatus,
  currentPath,
  confirmShipment,
  confirmShipmentFirst,
  searchText,
  setEditOrderModal,
}) {
  const [selected, setSelected] = useState([]);
  const [bagDetail, showBagDetail] = useState(null);
  const [sortedData, setSortedData] = useState(null);
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { isLoading } = userStore;
  const { exportPDF, exportXLS, exportLabel } = useExportMethods();
  const { resourceId } = useQueryMethods();
  const { resetFreshChat, openOrderChat } = useFreshChatMethods();
  const { filterOrdersPrepare, simplePriceWithOptions, getOpacity, showExtraLabel } = orderHelper;

  useEffect(() => {
    if (searchText === '') setSortedData(data);
    if (selected?.length === 0 || selected === undefined) {
      setSelected(data?.data?.map((item, index) => ({ index: index, stat: 'close' })));
    }
  }, [data?.data, sortedData]);

  useMemo(() => {
    let _selected = selected?.map(bag => {
      return { ...bag, stat: expandCollapse ? 'open' : 'close' };
    });
    setSelected(_selected);
  }, [expandCollapse]);

  useMemo(() => {
    if (searchText) {
      setSortedData(prevState => ({
        ...prevState,
        data: filterOrdersPrepare(searchText, data),
      }));
    }
  }, [searchText]);

  const ifExpanded = _index => {
    return selected?.filter(item => item.index === _index && item.stat === 'open')?.length > 0;
  };

  const setExpanded = _index => {
    let _selected = selected?.map(bag => {
      return bag.index === _index ? { ...bag, stat: bag.stat === 'open' ? 'close' : 'open' } : bag;
    });
    setSelected(_selected);
  };
  return (
    <div className="flex flex-col mt-4">
      {sortedData?.data?.map(
        (
          {
            attributes: {
              status,
              order_items,
              permanent_instructions,
              subtotal,
              cutlery,
              user_name,
              order_date,
              cutoff,
              hst,
              bag_number,
            },
          },
          index
        ) => (
          <div
            key={index}
            onClick={() => setExpanded(index)}
            className={`odd:bg-table-row rounded-md w-full bg-white shadow-lg p-3 self-center d-col justify-between mt-4 border border-gray-200`}>
            <div className="d-row justify-between pt-2">
              <div className={`py-1 break-words flex-row align-middle ${getOpacity(status)}`}>
                <div className="d-col">
                  {permanent_instructions ? (
                    <div className="d-row items-start">
                      <FontAwesomeIcon icon={faExclamationTriangle} color={'#FF9966'} size="xs" />
                      <small className="text-xs text-dark-gray font-inter-regular pl-1">
                        <div title={permanent_instructions}>{permanent_instructions}</div>
                      </small>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </div>

            <div className="d-row justify-between pt-2">
              <table className="w-full table-fixed">
                <thead>
                  <tr>
                    <th className="text-left text-dark-gray" style={{ width: '14%' }}>
                      <small className="t-row">Qty</small>
                    </th>

                    <th className="text-left text-dark-gray" style={{ width: '28%' }}>
                      <small className="t-row">Category</small>
                    </th>

                    <th className="text-left text-dark-gray" style={{ width: '58%' }}>
                      <small className="t-row">Item</small>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {order_items?.map(({ attributes }, orderItemIndex) => (
                    <tr key={orderItemIndex}>
                      <td colSpan={3}>
                        <table className="w-full table-fixed">
                          <tbody>
                            <tr>
                              <td
                                className="text-left align-top text-dark-gray"
                                style={{ width: '14%' }}>
                                <small className="font-inter-regular text-xs flex flex-col">
                                  {attributes?.quantity}
                                </small>
                              </td>

                              <td className="text-left align-top py-0" style={{ width: '28%' }}>
                                <p className="overflow-ellipsis overflow-hidden text-xs d-row items-start text-dark-gray font-inter-regular">
                                  {attributes.category}
                                </p>

                                {cutlery ? (
                                  <div className="flex my-3">
                                    <span className="mr-2 font-inter-regular text-xs text-black lg:text-sm">
                                      {attributes?.quantity}x
                                    </span>
                                    <FontAwesomeIcon
                                      icon={faUtensils}
                                      color={'#6D69D3'}
                                      size="sm"
                                    />
                                  </div>
                                ) : (
                                  <div />
                                )}
                              </td>

                              <td className="align-top" style={{ width: '58%' }}>
                                <p className="overflow-ellipsis overflow-hidden text-xs d-row items-start text-dark-gray font-inter-regular">
                                  {attributes?.special_instructions && (
                                    <img
                                      src={SpecialInstruction}
                                      className="object-contain h-6 w-6 pr-2"
                                    />
                                  )}
                                  {attributes?.menu_item?.title}
                                </p>

                                {attributes?.special_instructions && (
                                  <p className="overflow-ellipsis overflow-hidden text-xs lg:text-xs text-pink-500 font-inter-regular">
                                    {attributes?.special_instructions}
                                  </p>
                                )}
                              </td>
                            </tr>

                            <tr className="animate-fade-in-down">
                              <td colSpan="4">
                                <table className="w-full table-fixed">
                                  {attributes?.order_item_options?.map(({ attributes }, index) => (
                                    <tr key={index}>
                                      <td
                                        className="text-right justify-end flex"
                                        style={{ width: '14%' }}></td>

                                      <td style={{ width: '28%' }} />

                                      <td style={{ width: '58%' }}>
                                        <div className="d-col">
                                          <small className="font-inter-regular break-normal text-gray-500 text-xs pt-2">
                                            {attributes?.option_category_name}:{' '}
                                            {attributes?.option_name}
                                          </small>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </table>
                              </td>
                            </tr>

                            <tr className="animate-fade-in-down">
                              <td colSpan="4">
                                <table className="w-full">
                                  <tr>
                                    <td style={{ width: '14%' }} />

                                    <td style={{ width: '28%' }} />

                                    <td style={{ width: '58%' }}>
                                      <div className="flex flex-col my-2">
                                        <>
                                          {attributes?.notes !== '' &&
                                          attributes?.notes?.split('#@').length >= 1 ? (
                                            <>
                                              <p className="overflow-ellipsis text-black overflow-hidden mb-2 text-xs lg:text-xs font-inter-regular">
                                                {`${attributes?.quantity > 1 ? '1 - ' : ''} ${
                                                  attributes?.quantity
                                                } - ${attributes?.notes?.split('#@')} - ${
                                                  sortedData.destination_code
                                                }`}
                                              </p>
                                            </>
                                          ) : (
                                            <div className="flex flex-col">
                                              {showExtraLabel(attributes, sortedData)}
                                            </div>
                                          )}
                                        </>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}

      <BagDetail
        closeModal={() => {
          showBagDetail(null);
          resetFreshChat();
        }}
        orderData={bagDetail}
        currentPath={currentPath}
        destinationCode={sortedData?.destination_code}
        cutoff={sortedData?.cutoff}
      />

      <NotFound data={data?.data} message="No Orders Today" />

      <div className="d-col md:flex-row justify-between w-full bg-white my-4 items-center">
        <div className="d-row w-full">
          <button
            disabled={array.isEmpty(sortedData?.data)}
            onClick={() =>
              exportLabel(sortedData?.destination_code, sortedData?.meal_date, resourceId())
            }
            className="btn-outline text-xs w-1/2">
            <small>Export Labels</small>
          </button>

          <button
            disabled={array.isEmpty(sortedData?.data)}
            onClick={() =>
              exportPDF(sortedData?.destination_code, sortedData?.meal_date, resourceId())
            }
            className="btn-outline text-xs mx-2 w-1/2">
            <small>Export PDF</small>
          </button>

          <button
            disabled={array.isEmpty(sortedData?.data)}
            className={`btn-outline text-xs w-1/2 ${
              array.isEmpty(sortedData?.data) && 'cursor-not-allowed'
            }`}
            onClick={() =>
              exportXLS(sortedData?.destination_code, sortedData?.meal_date, resourceId())
            }>
            <small>Export XLS</small>
          </button>
        </div>

        <div className="w-full md:w-auto">
          {sortedData?.data &&
          sortedData?.data[0]?.attributes?.shipment_acceptance_status === null ? (
            <button
              onClick={confirmShipmentFirst}
              className="btn-purple w-full md:w-auto bg-red-600 px-14 lg:px-24 mt-2 md:mt-0">
              <small>Confirm Order</small>
            </button>
          ) : (
            <>
              {withSentStatus && !isLoading && (
                <button
                  onClick={() => confirmShipment()}
                  className="btn-purple w-full bg-red-600 text-xs mt-2">
                  <small>Confirm</small>
                </button>
              )}
            </>
          )}

          {data?.status === 'pending' && (
            <button
              onClick={() => setEditOrderModal(data)}
              className={`btn-purple text-xs mt-2 w-full`}>
              <small>Edit Order</small>
            </button>
          )}

          <button
            disabled={currentPath?.help}
            onClick={openOrderChat}
            className={`btn-purple text-xs mt-2 w-full ${currentPath?.help && 'opacity-50'}`}>
            <small>Help</small>
          </button>
        </div>
      </div>
    </div>
  );
}

export default observer(CateringOrderMobile);
